import React from "react"
import Layout from "../components/Layout"

const about = () => {
  return (
    <Layout>
     
        <h1>about page</h1>
     
    </Layout>
  )
}



export default about
